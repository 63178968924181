<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar dense class="tc-title">
      <v-toolbar-title>Branch Wise Candidates</v-toolbar-title>

    </v-toolbar>
    <v-card>
      <v-container fluid>
           
          <div>
        <v-data-table
        :headers="headers"
            :items="mainarray"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Branch Wise Candidates Count</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
         
                    <v-spacer></v-spacer>
                    <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    ></v-text-field>
                    <download-excel
                    class="mar"
                    v-if="mainarray.length != 0"
                    color="primary"
                    dark
                    :data="mainarray"
                    :fields="table_fields"
                    worksheet="My Worksheet"
                    name="Application Recevied Report.xls"
                    >
                    <v-btn color="success" dark class="mb-2">
                        <v-icon dark>mdi-file-excel</v-icon>
                    </v-btn>
                    </download-excel>
                    <!-- <v-btn v-if="learner_list.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
                </v-btn> -->
                    <span style="width: 20px"></span>
                </v-toolbar>
                </template>
                <template v-slot:item.count="{ item }">
                <div>
                    <span @click="getstudentbycount(item)">{{item.count}}</span>
                </div>
                </template>
      </v-data-table>
      <v-dialog v-model="dialogbranch" width="auto">
          <template>
            <v-card>
              <v-card-title class="c-title">
                <v-row justify="space-between">
                  Company Round Eligible List
                  <v-btn icon dark @click="dialogbranch = false">
                    <v-icon color="black">mdi-close</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>
              <!-- <div class="text-center">
                <v-chip class="ma-2" color="pink" label text-color="white">
                  <v-icon left>mdi-label</v-icon>
                  Note: Un-selecting previously selected candidates wont work
                </v-chip>
              </div>-->
              <v-card-text v-if="corl_list != null">
                <!-- <v-icon
                  large
                  color="orange darken-2"
                  class="icon-quick"
                  title="Back to rounds"
                  @click="closeList"
                  >mdi-backspace
                </v-icon> -->
                <!-- {{cor_data}} -->
                <template v-if="cor_data != null">
                  <v-chip class="ma-2" id="chip" label text-color="white"
                    >Round No: {{ cor_data.cor_round_no }}
                    
                    </v-chip
                  >
                  <v-chip class="ma-2" id="chip" label text-color="white"
                    >Round: {{ cor_data.cor_round_name }}</v-chip
                  >
                </template>
                <!-- data table         -->
                <!-- {{corl_list}} -->
                <template>
                  <v-card>
                    <v-card-title>
                      Eligible Candidates
                      <vue-excel-xlsx
                        :data="corl_list"
                        :columns="eli_columns"
                        :filename="'EligibleCandidates'"
                        :sheetname="'eligible_list'"
                      >
                        <v-icon
                          large
                          color="blue"
                          class="icon-quick"
                          title="Download"
                        >
                          mdi-file-excel
                        </v-icon>
                      </vue-excel-xlsx>
                      <v-spacer></v-spacer>
                      
                      <v-spacer></v-spacer>
                      <v-btn color="blue" dark @click="downloadallcv(corl_list)"
                        >Download All CV</v-btn
                      >
                      <!-- <v-btn  
                      color="blue"
                      dark
                      @click="downloadCv()"
                      >Download All CVs</v-btn>-->
                      <v-spacer></v-spacer>
                      
                     
                     

                      

                    
                      <v-spacer></v-spacer>
                      <!-- <v-text-field
                        v-model="eli_search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field> -->
                    </v-card-title>
                    <!-- <v-data-table
                      :headers="eli_headers"
                      :items="corl_list"
                      :search="eli_search"
                    >
                      <template v-slot:item.ispassed="{ item }">
                        <input style="height:40px;width:30px;" type="checkbox" v-model="item.ispassed">
                      </template>
                    </v-data-table> -->
                    <v-row class='pl-4'>
                        <v-col sm="8"></v-col>
                        <v-col sm="4">
                            <span style="float:right;height:80px;width:250px;">
                              <span style="font-weight:600;">P</span>  - Placement <br>
                              <span style="font-weight:600;">I</span>   - Internship<br>
                              <span style="font-weight:600;">P&I</span> - Placement And Internship 
                            </span>
                        </v-col>
                   </v-row>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">Sr. No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">PRN/GRNo</th>
                          <th class="text-left">Mobile No.</th>
                          <th class="text-left">Email Id</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">Placed In</th>
                          <th class="text-left">Program</th>
                          <th class="text-left">Year</th>
                          <th class="text-left">Organization</th>
                          <th class="text-left">CV</th>
                        </tr>
                      </thead>
                      <tbody>
                      
                        <tr v-for="(item, index) in corl_list" :key="index">
                          <td v-if="item.ispassed" style="color: green">
                            {{ index + 1 }}
                          </td>
                          <td v-if="item.ispassed" style="color: green">
                            {{ item.stud_name }}
                          </td>
                          <td v-if="item.ispassed" style="color: green">
                            <a @click="viewprofile(item)" style="cursor:pointer">{{ item.stud_grno }}</a>
                          </td>
                           <td v-if="item.ispassed" style="color: green">
                            {{ item.stud_mobile }}
                          </td>
                           <td v-if="item.ispassed" style="color: green">
                            {{ item.stud_email }}
                          </td>
                           <td v-if="item.ispassed" style="color: green">
                            {{ item.companyofferingroundlearnerstatus }}
                          </td>
                          <td v-if="item.ispassed" style="color: green">
                              {{item.placed}}
                          </td>
                          <td v-if="item.ispassed" style="color: green">
                            {{ item.stud_prog }}
                          </td>
                          <td v-if="item.ispassed" style="color: green">
                            {{ item.stud_year }}
                          </td>
                          <td v-if="item.ispassed" style="color: green">
                            {{item.stud_org}}
                          </td>
                          <td v-if="item.ispassed && item.stud_Cvid">
                            <v-icon
                              large
                              color="blue"
                              class="icon-quick"
                              title="CV Download"
                              @click="downloadcv(item.stud_Cvid)"
                            >
                              mdi-file-document
                            </v-icon>
                          </td>
                          <td
                            v-if="item.ispassed && !item.stud_Cvid"
                            style="color: green"
                          >
                            NA
                          </td>

                          <td v-if="!item.ispassed">{{ index + 1 }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_name }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_grno }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_mobile }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_email }}</td>
                          <td v-if="!item.ispassed">{{ item.companyofferingroundlearnerstatus }}</td>
                          <td v-if="!item.ispassed">{{item.placed}}</td>
                          <td v-if="!item.ispassed">{{ item.stud_prog }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_year }}</td>
                          <td v-if="!item.ispassed">{{ item.stud_org }}</td>
                          <td v-if="!item.ispassed && item.stud_Cvid">
                            <v-icon
                              large
                              color="blue"
                              class="icon-quick"
                              title="CV Download"
                              @click="downloadcv(item.stud_Cvid)"
                            >
                              mdi-file-document
                            </v-icon>
                          </td>

                          
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </template>

                <br />
                <v-row justify="center">
                  
                  <v-btn
                    class="btn-title"
                    outlined
                    text
                    @click="dialogbranch = false"
                    style="margin: 8px"
                  >
                    Close
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>


        </div>
        <v-overlay :value="overlay">
           <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      
      </v-container>
     
     
    </v-card>
  </div>
  
</template>
<script>
import readXlsxFile from "read-excel-file";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
import axios from "axios";
export default {
  data: () => ({
    dialog_add: false,
    dialog_upload:false,
    search: "",
    selected_company: null,
    items: "",
    companyofferingid:"",
    roundid:"",
    round:"",
    learnerlist:"",
    nameidmap: "",
    snackbar: "",
    mainarray:[],
    selectall:false,
    isselected:false,
    snackbar_msg: "",
    dialogbranch:false,
    body:"",
    subject:"",
    overlay:false,
        commode:"Email",
        countlist:[],
        offeringinfo:[],
      regstartdate:"",
        regenddate:"",
    color: "",
    scheduling_id: null,


    headers: [
      { text: "Program", value: "program" },
      { text: "Year", value: "year" },
      { text: "Organization", value: "organization" },
      { text: "Count", value: "count" }
      
      ],
      eli_columns: [
      { label: "SRNo", field: "srno" },
      { label: "Name", field: "stud_name" },
      { label: "GRNo", field: "stud_grno" },
      { label: "Mobile", field: "stud_mobile" },
      { label: "Email", field: "stud_email" },
      { label: "Department", field: "stud_dept" },
      { label: "Organization", field: "stud_org" },
      { label: "Program", field: "stud_prog" },
      { label: "CompanyOffering learner status", field: "companyofferingroundlearnerstatus" },
      { label: "Year", field: "stud_year" },
      { label: "Selected", field: "ispassed" },
    ],
      table_fields: {
    },



  }),
  watch: {
    dialog_edit(val) {
      val || this.close();
    },
  },

  
  
  methods: {
   
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    downloadallcv(corl_list) {
      var cvarray = [];
      for (var i = 0; i < corl_list.length; i++) {
        cvarray[i] = corl_list[i].stud_Cvid;
      }
      var params = {
        cv: cvarray,
      };
      axios.post("/onCampusProcess/downloadallcv", params).then((res) => {
        if (res.data.msg == "200") {
            //console.log(res.data.cvlist);
             const zip = new JSZip();
    let count = 0;
    const zipFilename = "Branch_wise_CV.zip";
    var cv= res.data.cvlist;
    cv.forEach(async function (url){
      // const urlArr = url.split('?')[0].split("/")
      // const filename = urlArr[urlArr.length - 1];
      // console.log(filename)
   
       JSZipUtils.getBinaryContent(url.url, function (err, data) {
            if(err) {
              //console.log(err);
                throw err; 
                // or handle the error
            }
            zip.file(url.grno, data, {binary:true});
            count++;
            if (count === res.data.cvlist.length) {
                zip.generateAsync({type:'blob'}).then(function(content) {
                    saveAs(content, zipFilename);
                });
            }
        });

    });


        }
      });
    },
    downloadcv(id) {
      var params = {
        cvid: id,
      };

      axios.post("/onCampusProcess/downloadcv", params).then((res) => {
        if (res.data.msg == "200") {
          window.open(res.data.cvurl, "_blank");
        }
      });
    },
    
   
   
     checkAll(hello) {
      
      for (var i = 0; i < this.learnerlist.length; i++) {
        if (!this.selectall) {
          this.learnerlist[i].isselected = true;
        } else {
          this.learnerlist[i].isselected = false;
        }
      }
    },
   
   onLoad()
    {
        //this.overlay=true;
        //console.log("round");
        //console.log(localStorage)
         var offering= {
            id : localStorage.getItem("scheduling_id"),
            round: localStorage.getItem("round")
        };
     axios
        .post("/onCampusProcess/getbranchwiseCandidates",offering)
        .then((res) => {
          if (res.data.msg == "200") {
            //4CAF50
            this.mainarray = res.data.mainarray;
            this.companyofferingid = res.data.companyofferingid;
            this.roundid =res.data.roundid
            //console.log("Hello")
            //console.log(res.data.mainarray);
            this.overlay=false;
            
          }

        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => {});
  },
   getstudentbycount(item)
   {
         const offering= {
            item:item,
            roundid:this.roundid,
        };
     axios
        .post("/onCampusProcess/getstudentbycount",offering)
        .then((res) => {
          if (res.data.msg == "200") {
            //console.log(res.data.corl_list);
            this.corl_list = res.data.corl_list;
            this.dialogbranch = true
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => {});

   },

   
  },
  mounted() {
     this.onLoad();
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.btn-title {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.c-title1 {
  background-image: linear-gradient(-90deg, #dbdbdb, #012944);
  color: #fff;
}
.link-icon {
  color: #fff;
}
.btn-title2 {
  background: linear-gradient(-90deg, #30cfd0 0%, #330867 100%);
}
</style>